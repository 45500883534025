import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, first, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserState } from '../shared/user';
import { environment } from '../../environments/environment';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.apiUrl;

  private userSubject!: BehaviorSubject<IUserState | null>;
  // public user: Observable<IUserState | null>;
  loggedIn$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    public http: HttpClient,
    private route: Router,
    private router: ActivatedRoute
  ) {
  }
  public getUserValue(): any {
    // this.getUserData()
    return localStorage.getItem('token');
  }

  private getUserEmail(): string {
    try {
      const decodedToken: any = jwtDecode(localStorage.getItem('token') as string);

      if (decodedToken && decodedToken.user && decodedToken.user.email) {
        return decodedToken.user.email;
      } else {
        console.error('Token structure is invalid or missing email field');
        return '';
      }
    } catch (err) {
      console.error('Error decoding token:', err);
      return '';
    }
  }

  public isCannesDemo(): boolean {
    return false;
    // return this.getUserEmail() === "5poundpixel@onlineseotoolweb.info"
  }

  public setUserValue(userData: IUserState) {
    this.userSubject.next(userData);
  }

  public isLoggedIn(): ReplaySubject<boolean> {
    this.loggedIn$.next(!!this.userSubject.value);
    return this.loggedIn$;
  }

  authToken(): Observable<any> {
    const token = localStorage.getItem('token')
    const url = `${this.baseUrl}/auth/callback?token=${token}`
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  verify(): Observable<any> {
    let url = `${this.baseUrl}/auth/verify?modifiedOn=${new Date().toISOString()}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  updateDOB(dob: string): Observable<any> {
    const url = `${this.baseUrl}/user/dob`;
    return this.http.post(url, { dob }, { withCredentials: true }).pipe(catchError(this.handleError));
  }
  cookieAccept(): Observable<any> {
    const url = `${this.baseUrl}/auth/cookie-accept?modifiedOn=${new Date().toISOString()}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  login(email: string, password: string, access_token?: string): Observable<any> {
    const url = `${this.baseUrl}/auth/login`;
    const payload = {
      email: email,
      password: password,
      access_token: access_token
    }
    return this.http
      .post(url, payload)
      .pipe(catchError(this.handleError));
  }
  forgotPassword(email: string, location: string): Observable<any> {
    const url = `${this.baseUrl}/auth/forgot-password`;
    const payload = {
      email,
      location
    }
    return this.http
      .post(url, payload)
      .pipe(catchError(this.handleError));
  }
  verifyPassword(form: { email: string, resetNumber: number }): Observable<any> {
    const url = `${this.baseUrl}/auth/verify-reset`;
    return this.http
      .post(url, form)
      .pipe(catchError(this.handleError));
  }
  setPassword(form: { password: string }): Observable<any> {
    const url = `${this.baseUrl}/auth/change-password`;
    return this.http
      .post(url, form)
      .pipe(catchError(this.handleError));
  }
  updateUser(agentName: string, companyName: string): Observable<any> {
    const token = localStorage.getItem('token')
    const url = `${this.baseUrl}/user/update-my-account`
    const payload = {
      agent: agentName,
      company: companyName
    }
    return this.http
      .post(url, payload)
      .pipe(catchError(this.handleError));
  }
  clearSiteData(): Observable<any> {
    const url = `${this.baseUrl}/auth/clear-site`
    return this.http
      .get(url)
      .pipe(catchError(this.handleError));
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    this.userSubject.next(null);
    window.location.href = '/';
  }

  createUser(email: string, password: string, fullName: string): Observable<any> {
    const token = localStorage.getItem('token');
    const url = `${this.baseUrl}/auth/create`;
    const payload = {
      email: email.toLowerCase(),
      password: password,
      full_name: fullName
    }
    return this.http
      .post(url, payload)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: any): Observable<any> {
    return throwError(error.error || 'Server error');
  }
}