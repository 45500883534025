export const environment = {
  level: 'development',
  production: true,
  pollingInterval: 3000,
  url: 'https://dev.donotfearthefuture.com',
  apiUrl: '/api/v1',
  wsEndpoint: 'wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000',
  stripe: '',
  name: '6656905c54f3c0c7ef208af3',
  friend: '6656913f5cf49dd1f298fcf5',
  goal: '665691965cf49dd1f298fe66'
};
